import React, { useState } from "react"

import Layout from "../../components/Layout"
import { Link } from "gatsby"
import Seo from "../../components/seo"

const ContactPage = () => {

  const [nameVal, setNameVal] = useState('');
  const [emailVal, setEmailVal] = useState('');
  const [messageVal, setMessageVal] = useState('');
  const isValid = !!(nameVal && emailVal && messageVal);


  return (
    <Layout >
      <Seo title="Contact" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading md:text-center">Contact</h1>
        
        <section className="max-w-prose mx-auto mt-10 lg:mt-20 ele-style lg:text-xl">

          <p>This form is for general questions or inquiring about selling your instrument to Tal's Guide!</p>
          <p>Do you need help finding a Talman?<br /> I've got a separate page just for that! <Link to="/help-find">Help me find a Talman</Link></p>
         
          <p className="text-sm">When inquiring about selling your instrument please provide as much info as possible such as model, year or era (if known), condition, modifications, included extras (case), etc. I'll try my best to find it a good home!</p>

          <form className="mt-8" name="contact" method="POST" action="/contact/success" data-netlify="true" netlify-honeypot="extra">
            <h2 className="form-heading">Contact Tal's Guide</h2>

            <input type="hidden" name="form-name" value="contact" />
            
            <div className="form-field">
              <label htmlFor="name">Full name <span className="required">(required)</span></label>
              <input id="name" name="name" type="text" value={nameVal} onChange={(e) => {
                  setNameVal(e.target.value);
                }} required />
            </div>
            
            <div className="form-field">
              <label htmlFor="email">Email <span className="required">(required)</span></label>
              <input id="email" name="email" type="email" value={emailVal} onChange={(e) => {
                  setEmailVal(e.target.value);
                }} required />
            </div>
           
            <div className="form-field">
              <label htmlFor="message">Message <span className="required">(required)</span></label>
              <textarea className="h-40" id="message" name="message" value={messageVal} onChange={(e) => {
                  setMessageVal(e.target.value);
                }} required />
            </div>

            <div className="form-field form-field--extra">
              <input id="extra" name="extra" type="text" />
            </div>
            
            <input className={`btn mt-6 ${!isValid ? 'btn-disabled' : ''}`} disabled={!isValid} type="submit" value="submit" />
            {!isValid ? <div className="text-xs mt-2">Please fill out all form fields to submit</div> : null}
          </form>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage